import http from "../http-common";
/* eslint-disable */
class PibBroadcastUserService {
  pesertaList(page, itemPerPage, keyword, id) {
    return http.get(
      "/broadcast/user/list?page=" +
        page +
        "&item_per_page=" +
        itemPerPage +
        "&keyword=" +
        keyword +
        "&id=" +
        id,
      {
        headers: {
          "Content-type": "application/json",
          Authorization:
            "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
        },
      },
    );
  }

  create(data) {
    var bodyFormData = new FormData();
    if (data.broadcast_id)
      bodyFormData.append("broadcast_id", data.broadcast_id);
    if (data.nama) bodyFormData.append("nama", data.nama);
    bodyFormData.append("email", data.email);
    if (data.whatsapp) bodyFormData.append("whatsapp", data.whatsapp);

    return http.post("/broadcast/user", bodyFormData, {
      headers: {
        "Content-Type": "application/json",
        Authorization:
          "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
      },
    });
  }

  update(data) {
    var bodyFormData = new FormData();
    bodyFormData.append("id", data.id);

    if (data.nama) bodyFormData.append("nama", data.nama);
    bodyFormData.append("email", data.email);
    if (data.whatsapp) bodyFormData.append("whatsapp", data.whatsapp);

    return http.put(`/broadcast/user/${data.id}`, bodyFormData, {
      headers: {
        "Content-Type": "application/json",
        Authorization:
          "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
      },
    });
  }

  send(id, broadcastId) {
    var bodyFormData = new FormData();
    bodyFormData.append("broadcast_id", broadcastId);
    bodyFormData.append("id", id);

    return http.post("/broadcast/user/send", bodyFormData, {
      headers: {
        "Content-Type": "application/json",
        Authorization:
          "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
      },
    });
  }

  copyPeserta(data) {
    var bodyFormData = new FormData();
    if (data.broadcast_id)
      bodyFormData.append("broadcast_id", data.broadcast_id);

    if (data.jenis_peserta && data.jenis_peserta != undefined) {
      bodyFormData.append("jenis_peserta", data.jenis_peserta.id);
    }

    if (data.selectedDpw && data.selectedDpw != undefined) {
      bodyFormData.append("dpw_id", data.selectedDpw.id);
    }

    if (data.selectedDpd && data.selectedDpd != undefined) {
      bodyFormData.append("dpd_id", data.selectedDpd.id);
    }

    if (data.selectedKomisariat && data.selectedKomisariat != undefined) {
      bodyFormData.append("komisariat_id", data.selectedKomisariat.id);
    }

    if (data.pib_id) bodyFormData.append("pib_id", data.pib_id);
    if (data.event_id) bodyFormData.append("event_id", data.event_id);

    return http.post("/broadcast/user/copy", bodyFormData, {
      headers: {
        "Content-Type": "application/json",
        Authorization:
          "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
      },
    });
  }

  uploadPeserta(data) {
    var bodyFormData = new FormData();
    if (data.broadcast_id)
      bodyFormData.append("broadcast_id", data.broadcast_id);

    if (data.file) bodyFormData.append("file", data.file);

    return http.post("/broadcast/user/upload", bodyFormData, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization:
          "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
      },
    });
  }

  deleteAllPeserta(id) {
    var bodyFormData = new FormData();
    bodyFormData.append("broadcast_id", id);

    return http.post("/broadcast/user/deleteall", bodyFormData, {
      headers: {
        "Content-Type": "application/json",
        Authorization:
          "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
      },
    });
  }

  share(userId, broadcastId) {
    return http.get(
      `/pib-user-broadcast/share-wa?user_id=${userId}&broadcast_id=${broadcastId}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization:
            "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
        },
      },
    );
  }

  remove(id) {
    return http.delete(`/broadcast/user/${id}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization:
          "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
      },
    });
  }
}

export default new PibBroadcastUserService();
